import Section from 'components/Section';
import React from 'react';

const Gallery = () => {
  return (
    <Section
      secondary
      title="Gallery"
      description="Check out the latest snaps from our meetings!"
    />
  );
};

export default Gallery;
