import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
`;

export const Position = styled.h4`
  width: 50px;
`;

export const Name = styled.h4`
  width: 200px;
`;

export const Points = styled.p`
  font-size: 14px;
  width: 150px;
  font-family: 'NotoSansMono';
`;

export const ChallengesContainer = styled.div`
  display: flex;
  gap: 5px;
`;
