import GtIcon from 'assets/images/gt.png';

const projects = [
  {
    name: 'GopherTunnels',
    icon: GtIcon,
    link: 'https://github.com/gopher-tunnels',
  },
];

export default projects;
